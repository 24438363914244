.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(131,175,240, 0.4); /* Dark background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup-content {
    background-color: #ffffff;
    padding: 40px 50px; /* Increase padding for more space */
    border-radius: 15px; /* Increase border radius */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 600px; /* Increase width */
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center; /* Center align text */
  }
  
  .popup-content h4 {
    font-family: 'Open Sans', sans-serif;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 300px;
    display: flex;
    margin-bottom: 30px;
  }
  
  .popup-content input[type="email"] {
    width: 70%; /* Make input smaller */
    padding: 7px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-family: 'Poppins', sans-serif;
    text-align: center;
  }

  .popup-content #code{
    margin-bottom: 20px;
  }

  .popup-content .error-message{
    color: red;
  }
  
  .checkbox-container {
    display: flex;
    flex-direction: row; /* Align items vertically */
    align-items: center; /* Center align checkbox and text */
    justify-content: center;
    margin: -10px 0 30px 0; /* Increase bottom margin */
    font-family: 'Poppins', sans-serif;
    width: 70%;
  }
  
  .checkbox-container input[type="checkbox"] {
    margin-right: 10px; /* Add margin below checkbox */
    width: 20px; /* Increase checkbox width */
    height: 20px; /* Increase checkbox height */
  }
  
  .checkbox-container span {
    font-size: 13px;
    justify-content: center;
  }
  
  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .checkbox-container a {
    color: #d77a77;
    text-decoration: none;
  }

  p a {
    color: #d77a77;
    text-decoration: none;
  }
  
  .legals {
    margin-top:3px;
  }

  .demo-buttons{
    flex-direction: row;
  }
  .submit-button {
    background-color: #A3C4BC;
    margin-left: 10px;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 25px;
    font-size: 15px;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    transition: background-color 0.3s ease;
  }

  .cancel-button {
    background-color: #d77a77;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 25px;
    font-size: 15px;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #88b09a;
  }
  
  .logo {
    width: 80px; /* Adjust size as needed */
    margin-left: 10px;
    align-self: center;
  }
  
  .highlight {
    color: #4CAF50;
  }

  .error{
    color: red;
  }
  
  p{
    font-size: 10px;
  }

  @media (max-width: 768px) {
    .checkbox-container span {
      font-size: 10px;
    }
    .logo{
      width: 55px; /* Adjust size as needed */
      margin-left: 8px;
      align-self: center;
    }
    .popup-content h2{
      font-size: 20px;
    }
    .popup-content h1{
      font-size: 25px;
    }
    .submit-button {
      font-size: 15px;
    }
    .popup-content {
      height: 300px;
      width: 400px;
    }
    label{
      font-size: 15px;
    }
    .popup-content input[type="text"] {
      width: 70%; /* Make input smaller */
      padding: 5px;
      margin: 5px 0 0 0; /* Increase bottom margin */
      border: 1px solid #ccc;
      border-radius: 5px;
      font-family: 'Poppins', sans-serif;
      text-align: center;
      font-size: 15px;
    }

  }