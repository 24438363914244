#footer {
    padding: 28px 0;
    background: #f1f6fe;
    @media all and (max-width: 575px) {
        padding: 18px 0;
    }

    .content-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media all and (max-width: 991px) {
            flex-direction: column-reverse;
            gap: 20px;
        }
    }

    .left-side {
        display: flex;
        align-items: center;
        gap: 20px;
        @media all and (max-width: 991px) {
            flex-direction: column;
        }

        img {
            @media all and (max-width: 575px) {
                width: 60px;
            }
            width: 120px;
        }
    }

    p {
        color: #292d32;
        font-size: 18px;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: 1.8px;
        text-transform: uppercase;
        margin: 0;
        @media all and (max-width: 575px) {
            font-size: 14px;
            text-align: center;
            letter-spacing: normal;
        }
    }

    .social-media {
        display: flex;
        align-items: center;
        gap: 8px;
        .item {
            width: 40px;
            height: 40px;
            border-radius: 32px;
            border: 1px rgba(255, 255, 255, 0.15);
            background: #1255bd;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
