#download-now {
    padding-bottom: 80px;
    .content-wrapper {
        border-radius: 20px;
        border: 1px solid #dee6fd;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.68) 0%, rgba(255, 255, 255, 0.68) 100%),
            linear-gradient(270deg, #d6e5fb 0%, rgba(71, 121, 196, 0.2) 50.16%, rgba(214, 229, 251, 0.5) 100%);

        padding: 32px 68px 0px 96px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media all and (max-width: 1024px) {
            flex-direction: column;
            gap: 30px;
        }
        @media all and (max-width: 1024px) {
            padding: 32px 32px 0 32px;
        }
    }

    .text-side {
        max-width: 462px;
    }

    h1 {
        color: #000;
        font-size: 40px;
        font-weight: 600;
        line-height: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 9px;
        margin: 0;
        margin-bottom: 50px;
        @media all and (max-width: 1024px) {
            font-size: 24px;
            margin-bottom: 25px;
        }
        @media all and (max-width: 575px) {
            gap: 5px;
        }
    }
    p {
        color: #666;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 180%;
        margin-bottom: 50px;
        @media all and (max-width: 575px) {
            margin-bottom: 25px;
            font-size: 14px;
        }
        span {
            color: #1255bd;
        }
    }

    svg {
        width: 40px;
        @media all and (max-width: 1024px) {
            width: 20px;
        }
    }

    .app-store {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;
        padding-bottom: 95px;
        @media all and (max-width: 1024px) {
            padding-bottom: 15px;
        }
    }
}
