#hero {
    padding-bottom: 200px;
    @media all and (max-width: 1024px) {
        padding-bottom: 100px;
    }
    @media all and (max-width: 575px) {
        padding-bottom: 80px;
    }
    .hero-contents {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media all and (max-width: 1024px) {
            flex-direction: column-reverse;
            align-items: flex-start;
            gap: 50px;
        }
    }

    .text-side {
        max-width: 516px;
    }
    h2 {
        color: #000;
        font-size: 41px;
        font-weight: 700;
        line-height: 64px;
        max-width: 649px;
        margin: 0;
        margin-top: 12px;
        margin-right: -130px;
        @media all and (max-width: 1024px) {
            margin-right: 0px;
        }
        @media all and (max-width: 575px) {
            font-size: 28px;
            line-height: normal;
        }
        span {
            color: #1255bd;
        }
    }

    p {
        color: #000;
        font-size: 20px;
        font-weight: 500;
        line-height: 180%;
        margin-top: 32px;
        margin-bottom: 26px;
        @media all and (max-width: 575px) {
            font-size: 18px;
            line-height: normal;
            margin-top: 22px;
            margin-bottom: 26px;
        }
    }
}
