
.blog-post {
    .logoblog {
        @media all and (max-width: 1024px) {
            max-width: 75px;
        }
        max-width: 120px;
    }

    padding: 20px;
    padding-top: 5px;
    max-width: 800px;
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 5px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  
    .back-button {
      background-color: #007bff;
      color: #fff;
      padding: 10px 15px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      margin-bottom: 20px;
      font-size: 1rem;
      transition: background-color 0.3s;
  
      &:hover {
        background-color: #0056b3;
      }
    }
  
    .blog-post-image {
      width: 100%;
      height: auto;
      border-radius: 5px;
      margin-bottom: 20px;
    }
  
    h1 {
      margin: 20px 0;
      font-size: 2rem;
      color: #333;
    }
  
    p {
      color: #555;
      margin-bottom: 10px;
      line-height: 1.8;
      font-size: 1.1rem;
    }
  
    .blog-section {
      margin-bottom: 20px;
      padding-left: 15px;
      border-left: 3px solid #007bff;
  
      p {
        margin-bottom: 10px;
        font-size: 1rem;
      }
    }
  
    // Mobile responsiveness
    @media (max-width: 768px) {
      padding: 15px;
  
      h1 {
        font-size: 1.8rem; // Smaller title on tablets
      }
  
      p {
        font-size: 1rem; // Smaller paragraph text on tablets
      }
  
      .blog-section {
        margin-bottom: 15px;
      }
    }
  
    @media (max-width: 480px) {
      padding: 10px;
  
      h1 {
        font-size: 1.5rem; // Smaller title on mobile
      }
  
      p {
        font-size: 0.9rem; // Smaller paragraph text on mobile
      }
  
      .blog-section {
        margin-bottom: 10px;
      }
  
      .back-button {
        padding: 8px 12px;
        font-size: 0.9rem; // Smaller button on mobile
      }
    }
  }
  