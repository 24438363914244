// Components/EmailPopup/EmailPopup.scss

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); // Dim background
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup {
    background-color: #fff;
    padding: 2rem;
    border-radius: 10px;
    width: 90%;
    max-width: 400px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    position: relative;
    text-align: center;
    h2 {
        span {
            color: #0050cb;
        }
    }
  }
  
  .close-btn {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #0050cb;
  }
  
  p {
    font-size: 0.85rem;
    margin-bottom: 1.5rem;
    color: #333;
  }
  
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  
    input[type="email"] {
      width: 100%;
      padding: 0.75rem;
      border: 1px solid #ddd;
      border-radius: 5px;
      font-size: 1rem;
      margin-bottom: 1rem;
      outline: none;
      transition: border 0.3s ease-in-out;
      text-align: center;
  
      &:focus {
        border-color: #0050cb;
      }
    }
  
    button.primary-btn {
      width: 100%;
      padding: 0.75rem;
      border: none;
      border-radius: 5px;
      background-color: #0050cb;
      color: #fff;
      font-weight: 600;
      cursor: pointer;
      transition: background-color 0.3s ease;
  
      &:hover {
        background-color: #003d9c;
      }
    }
  }
  
  .error {
    color: red;
    font-size: 0.875rem;
    margin-top: -0.75rem;
    margin-bottom: 1rem;
  }
  
  .promo-section {
    h2 {
      color: #0050cb;
      margin-bottom: 1rem;
    }
  
    p {
      font-size: 1rem;
      color: #333;
      strong {
        font-size: 1.25rem;
        color: #0050cb;
        font-weight: bold;
      }
    }
  }
  