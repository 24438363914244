
.logoblog {
    @media all and (max-width: 1024px) {
        max-width: 75px;
    }
    max-width: 120px;
    img {
        @media all and (max-width: 1024px) {
            max-width: 75px;
        }
        max-width: 120px;
    }
    margin-top: 32px;
    margin-bottom: 43px;
    @media all and (max-width: 1024px) {
        padding-top: 12px;
    }
}

.logoblog:hover {
  cursor: pointer;
}

.blog-list {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: space-around;


    .blog-item {
      border: 1px solid #ddd;
      padding: 10px;
      border-radius: 5px;
      width: calc(33.333% - 20px);
      box-sizing: border-box;
      background-color: white;
      transition: transform 0.3s, box-shadow 0.3s;
  
      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
      }
  
      .blog-image {
        width: 100%;
        height: auto;
        border-radius: 5px;
      }
  
      h2 {
        margin: 10px 0;
        font-size: 1.5rem;
        color: #333;
      }
  
      p {
        color: #555;
        margin-bottom: 5px;
      }
  
      a {
        text-decoration: none;
        color: inherit;
        display: block;
      }
    }
  
    // Mobile responsiveness
    @media (max-width: 768px) {
      .blog-item {
        width: calc(50% - 20px); // Two columns on tablets
      }
    }
  
    @media (max-width: 480px) {
      .blog-item {
        width: 100%; // Single column on mobile
      }
  
      .blog-item h2 {
        font-size: 1.2rem; // Smaller heading on mobile
      }
  
      .blog-item p {
        font-size: 0.9rem; // Smaller paragraph text on mobile
      }
    }
  }
  