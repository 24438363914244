#struggling {
    padding-bottom: 140px;
    @media all and (max-width: 1024px) {
        padding-bottom: 100px;
    }
    @media all and (max-width: 575px) {
        padding-bottom: 80px;
    }
    .content-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 41px;

        @media all and (max-width: 1024px) {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    .primary-btn {
        background-color: #000;
        &:hover {
            color: #000;
            background-color: #fff;
        }
    }

    h3 {
        color: #000;
        font-size: 36px;
        font-weight: 600;
        line-height: 160%;
        max-width: 630px;
        margin: 0;
        @media all and (max-width: 575px) {
            font-size: 28px;
            line-height: normal;
        }
    }

    p {
        color: #666;
        font-size: 16px;
        font-weight: 400;
        line-height: 180%;
        margin-top: 24px;
        margin-bottom: 32px;
    }
}
