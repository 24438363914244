#using-blive {
    padding-bottom: 171px;
    @media all and (max-width: 1024px) {
        padding-bottom: 100px;
    }
    @media all and (max-width: 575px) {
        padding-bottom: 80px;
    }

    .content-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 60px;
        @media all and (max-width: 1024px) {
            display: flex;
            flex-direction: column-reverse;
            gap: 20px;
        }
    }
    h3 {
        color: #000;
        font-size: 36px;
        font-weight: 600;
        line-height: 160%;
        margin: 0;
        @media all and (max-width: 575px) {
            font-size: 28px;
        }
    }
    p {
        color: #666;
        font-size: 16px;
        font-weight: 400;
        line-height: 180%;
        margin-top: 12px;
        margin-bottom: 18px;
        @media all and (max-width: 575px) {
            font-size: 16px;
        }
    }
    h6 {
        color: #000;
        font-size: 20px;
        font-weight: 600;
        line-height: 180%;
        margin: 0;
        @media all and (max-width: 575px) {
            font-size: 18px;
        }
    }

    .boxs-group {
        display: grid;
        gap: 14px;
        .circle {
            width: 100%;
            border-radius: 100%;
            margin-top: 6px;
            aspect-ratio: 1/1;
        }
        .box {
            border-radius: 20px;
            border: 1px solid #ccd9fe;
            background: rgba(246, 248, 253, 0.6);
            padding: 16px 19px;
            display: grid;
            grid-template-columns: 24px 1fr;
            gap: 13px;
            @media all and (max-width: 575px) {
                grid-template-columns: 16px 1fr;
                gap: 8px;
                padding: 10px;
            }
        }
    }

    a {
        color: #666;
        font-size: 16px;
        font-weight: 500;
        line-height: normal;
        text-decoration-line: underline;
        display: block;
        margin: 24px 0;
    }
}
