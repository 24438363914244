#header {
    padding-top: 32px;
    padding-bottom: 43px;
    @media all and (max-width: 1024px) {
        padding-top: 12px;
    }
    .content {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .navigation{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        
    }

    .blog-button{
        margin-right: 10px;
    }

    .logo {
        img {
            @media all and (max-width: 1024px) {
                max-width: 75px;
            }
            max-width: 120px;
        }
    }
}
