#interactive-story {
    padding-bottom: 140px;
    @media all and (max-width: 1024px) {
        padding-bottom: 100px;
    }
    @media all and (max-width: 575px) {
        padding-bottom: 80px;
    }
    h2 {
        color: #000;
        font-size: 36px;
        font-weight: 600;
        line-height: 160%;
        margin: 0;
        @media all and (max-width: 668px) {
            font-size: 28px;
            line-height: normal;
        }
    }
    p {
        color: #666;
        font-size: 16px;
        font-weight: 400;
        line-height: 180%;
        margin-top: 8px;
        margin-bottom: 28px;
        max-width: 729px;
    }
    .box-group {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;
        @media all and (max-width: 1250px){
            grid-template-columns: repeat(2, 1fr);
        }
        @media all and (max-width: 668px) {
            grid-template-columns: repeat(1, 1fr);
        }
        .box {
            padding: 36px 26px;
            border-radius: 20px;
            border: 2px solid #d5dffe;
            background: #eff5f9;
            &:nth-child(even) {
                margin-top: 60px;
                @media all and (max-width: 668px) {
                    margin-top: 0px;
                }
            }
            &:nth-child(odd) {
                margin-bottom: 60px;
                @media all and (max-width: 668px) {
                    margin-bottom: 0px;
                }
            }
        }
        p {
            padding-top: 31px;
        }
    }
    h4 {
        color: #000;
        font-size: 24px;
        font-weight: 600;
        line-height: normal;
        margin: 0;
        padding-left: 9px;
    }
}
