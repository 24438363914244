#testimonial {
    padding-bottom: 198px;
    @media all and (max-width: 1024px) {
        padding-bottom: 120px;
    }
    @media all and (max-width: 575px) {
        padding-bottom: 80px;
    }

    .content-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        @media all and (max-width: 1024px) {
            grid-template-columns: repeat(1, 1fr);
            gap: 30px;
        }
        gap: 60px;
    }

    p {
        color: #000;
        font-size: 16px;
        font-weight: 400;
        line-height: 180%;
        padding: 6px 48px;
        margin: 0;
        @media all and (max-width: 575px) {
            font-size: 14px;
            padding: 5px 18px;
        }
    }

    h3 {
        color: #000;
        font-size: 24px;
        font-weight: 600;
        line-height: normal;
        margin: 0;
        @media all and (max-width: 575px) {
            font-size: 20px;
        }
    }
    h4 {
        color: #858692;
        font-size: 20px;
        font-weight: 600;
        line-height: 180%;
        margin: 0;
        @media all and (max-width: 575px) {
            font-size: 18px;
        }
    }

    h2 {
        color: #1255bd;
        font-size: 96px;
        font-weight: 400;
        line-height: 0%;
        margin: 0;
        @media all and (max-width: 575px) {
            font-size: 64px;
        }
    }
    .writter-detail {
        margin-top: 50px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 18px;
        @media all and (max-width: 575px) {
            gap: 8px;
            margin-top: 30px;
        }

        img {
            @media all and (max-width: 575px) {
                width: 50px;
            }
        }
    }

    .next-prev-btn-groups {
        display: flex;
        align-items: center;
        gap: 20px;
        position: absolute;
        bottom: 15px;
        right: 0px;
        z-index: 10;
        @media all and (max-width: 575px) {
            gap: 10px;
        }
        button {
            display: inline-flex;
            padding: 12.308px;
            justify-content: center;
            align-items: center;
            border-radius: 100%;
            border: 1.758px solid #1255bd;
            background-color: #fff;
            outline: none;
            aspect-ratio: 1 / 1;
            transition: all 0.3s ease-in-out;
            @media all and (max-width: 575px) {
                width: 30px;
                height: 30px;
                padding: 5px;
            }
            &:last-child {
                transform: rotate(180deg);
            }

            &:hover {
                background-color: #1255bd;
                svg {
                    path {
                        fill: white;
                    }
                }
            }
        }

        button.active {
            background-color: #1255bd;
            svg {
                path {
                    fill: #fff;
                }
            }

            &:hover {
                background-color: #fff;
                svg {
                    path {
                        fill: #000;
                    }
                }
            }
        }

        button.disabled {
            cursor: not-allowed;
            &:hover {
                background-color: #fff;
                svg {
                    path {
                        fill: #000;
                    }
                }
            }
        }
    }

    .qoute-img {
        @media all and (max-width: 575px) {
            width: 20px;
        }
    }

    .slider-wrapper {
        overflow: hidden !important;
    }
    .swiper-wrapper {
        display: flex !important;
        height: 100% !important;
    }

    .swiper-slide {
        width: 100% !important;
        height: 100% !important;
        display: inline-flex !important;
    }
}
