#discover {
    padding-bottom: 111px;
    @media all and (max-width: 1024px) {
        padding-bottom: 80px;
    }
    @media all and (max-width: 575px) {
        padding-bottom: 60px;
    }

    h1 {
        color: #000;
        font-size: 36px;
        font-weight: 600;
        line-height: 160%;
        text-align: center;
        margin-bottom: 14px;
        @media all and (max-width: 575px) {
            font-size: 28px;
            line-height: normal;
            margin-bottom: 8px;
        }
    }
    p {
        color: #666;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 180%;
        margin-bottom: 67px;
        @media all and (max-width: 575px) {
            margin-bottom: 37px;
            font-size: 14px;
        }
    }

    .content-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 27px;

        @media all and (max-width: 1024px) {
            grid-template-columns: repeat(1, 1fr);
        }

        .item {
            border-radius: 20px;
            border: 2px solid #dee6fd;
            background: #eff5f9;
            display: flex;
            flex-direction: column;
            align-items: center;
            p {
                color: #000;
                margin-top: 17px;
            }
        }

        .item1 {
            padding: 75px 0px 2px;
            @media all and (max-width: 575px) {
                padding: 20px 0 0;
            }

            .text-content {
                padding: 0px 50px 0px;
            }

            p {
                max-width: 454px;
                margin-bottom: 22px;
            }
            br {
                @media all and (max-width: 575px) {
                    display: none;
                }
            }

            img {
                margin-top: 101px;
                @media all and (max-width: 575px) {
                    margin-top: 30px;
                }
            }
        }

        .item2 {
            background: #f6f8fd;
            padding: 60px 0px 52px;

            @media all and (max-width: 575px) {
                padding: 20px 0;
            }

            .text-content {
                padding: 0px 50px 0px;
                @media all and (max-width: 575px) {
                    padding: 0px 20px 0px;
                }
            }
            img {
                margin-bottom: 20px;
                width: 100%;
            }
            p {
                margin-bottom: 13px;
                max-width: 540px;
            }
        }
    }

    h3 {
        color: #000;
        font-size: 24px;
        font-weight: 600;
        line-height: normal;
        display: flex;
        align-items: center;
        gap: 9px;
        margin: 0;
        @media all and (max-width: 575px) {
            font-size: 18px;
            gap: 3px;
        }

        svg {
            @media all and (max-width: 575px) {
                width: 16px;
            }
        }
    }
}
