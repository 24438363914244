@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

.container {
  max-width: 1412px !important;
  padding: 0 48px !important;
}

@media all and (max-width: 1024px) {
  .container {
    padding: 0 24px !important;
  }
}
@media all and (max-width: 425px) {
  .container {
    padding: 0 12px !important;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Inter", sans-serif;
}

body {
  font-family: "Inter", sans-serif;
  box-sizing: border-box;
  background-image: url(../public/assets/img/hero-bg.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
}

button.primary-btn {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 17px 25px;
  border-radius: 40px;
  border: 1px solid #0050cb;
  background: #0050cb;
  transition: all 0.3s ease-in-out;
  @media all and (max-width: 575px) {
    padding: 10px 15px;
  }

  &:hover {
    background-color: #fff;
    color: #0050cb;
  }
}
